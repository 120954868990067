import _node from "./node";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// DOM-Level-1-compliant structure
var NodePrototype = _node;
var ElementPrototype = exports = Object.create(NodePrototype);
var domLvl1 = {
  tagName: "name"
};
Object.keys(domLvl1).forEach(function (key) {
  var shorthand = domLvl1[key];
  Object.defineProperty(ElementPrototype, key, {
    get: function () {
      return (this || _global)[shorthand] || null;
    },
    set: function (val) {
      (this || _global)[shorthand] = val;
      return val;
    }
  });
});
export default exports;